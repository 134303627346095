import React, { useContext } from "react";
import moment from "moment";
import { ModalContext } from "../../context/ModalContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getValue } from "../../utils";

const CancelClass = ({ single_class, reservations, cancelReservacion }) => {
  const { clearModal } = useContext(ModalContext);
  const appconfig = useContext(AppConfigContext);

  const { cancel_timeframe_value } = appconfig;

  const allow_cancel_after_treshold = getValue(
    appconfig,
    "allow_cancel_after_treshold",
    "boolean"
  );

  const getClassReservation = () => {
    if (single_class) {
      if (single_class.class_reservation_id) {
        return single_class.class_reservation_id;
      }
    }
    if (reservations && reservations !== null) {
      const class_reservation = reservations.find(
        (reservation) =>
          parseInt(reservation.single_class_id) ===
            parseInt(single_class.single_class_id) &&
          reservation.deletedAt === null
      );
      if (class_reservation) {
        return class_reservation.class_reservation_id;
      }
    }
  };

  const class_reservation_id = getClassReservation();

  const diff =
    moment(single_class.class_date)
      .utc()
      .local()
      .diff(moment().utc().local(), "hours") + 6;

  return (
    <div className="container-fluid px-0">
      <p>
        ¿Deseas cancelar tu clase{" "}
        {single_class.single_class
          ? single_class.single_class.class_type.name
          : single_class.class_type.name}{" "}
        del {moment(single_class.class_date).format("DD MMM YYYY")} a las{" "}
        {moment(single_class.class_date).utc().format("HH:mm")}
      </p>
      {allow_cancel_after_treshold && diff < cancel_timeframe_value && (
        <p className="text-danger">
          Debido a que faltan menos de {cancel_timeframe_value} horas para tu
          clase, tu crédito para reservar clase no se te regresará.
        </p>
      )}
      <div className="row">
        <div className="col col-md-6">
          <button className="btn w-100 text-muted" onClick={clearModal}>
            Regresar
          </button>
        </div>
        <div className="col col-md-6">
          <button
            className="btn btn-danger w-100"
            onClick={() => cancelReservacion(class_reservation_id)}
          >
            Cancelar Clase
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelClass;
