import React, { useContext, useEffect } from "react";
import Page from "../components/global/Page";
import InstructorRow from "../components/instructors/InstructorRow";
import { InstructorsContext } from "../context/InstructorsContext";

const Instructors = ({ isPanel }) => {
  const { instructors, getHomeInstructors } = useContext(InstructorsContext);

  useEffect(() => {
    getHomeInstructors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderInstructors = () => {
    if (Array.isArray(instructors)) {
      if (instructors.length > 0) {
        return instructors.map((instructor) => (
          <InstructorRow
            key={instructor.instructor_id}
            instructor={instructor}
          />
        ));
      }
    }
  };

  return (
    <Page isPanel={isPanel} title="Meet Your Coaches">
      {renderInstructors()}
    </Page>
  );
};
export default Instructors;
