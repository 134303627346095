import api from "./api";

const route = "/discounts";

const DescuentosService = {
  getSingleDescuento: (discount_id) =>
    api.get(`${route}/descuento/${discount_id}`),
  validarDescuento: (code, class_package_id) =>
    api.get(`${route}/code/${code}?class_package_id=${class_package_id}`),
  getDescuentosAdmin: () => api.get(`${route}/admin`),
  postDescuento: (descuento) => api.post(route, { ...descuento }),
  putDescuento: (descuento) => api.put(route, { ...descuento }),
  deleteDescuento: (discount_id) => api.delete(`${route}/${discount_id}`),
};

export default DescuentosService;
