import React, { useContext, useState } from "react";
import moment from "moment";
import MapaLugares from "./MapaLugares";
import SingleCoach from "../coaches/SingleCoach";
import { ModalContext } from "../../context/ModalContext";

const SingleClass = ({ guest, clase, postReservacion }) => {
  const [place, setPlace] = useState(null);
  const [guestName, setGuestName] = useState("");

  const { alert, clearModal } = useContext(ModalContext);

  const handleSubmit = () => {
    if(guest && String(guestName).length < 2) {
      return alert("Debes ingresar un nombre para tu invitado");
    }
    postReservacion({ ...clase, spot: place, guest: guestName });
  }

  const renderGuest = () => {
    if (guest) {
      return (
        <div>
          <label>Nombre de Invitado</label>
          <input
            type="text"
            value={guestName}
            className="form-control mb-3"
            onChange={(e) => setGuestName(e.target.value)}
          />
        </div>
      );
    }
  };

  const renderCoaches = () => {
    if (Array.isArray(clase.class_instructors)) {
      return clase.class_instructors.map((class_instructor) => {
        return (
          <SingleCoach
            key={class_instructor.instructor.instructor_id}
            coach={class_instructor.instructor}
          />
        );
      });
    }
  };

  const renderIcon = () => {
    if (clase.class_type && clase.class_type !== null) {
      return (
        <>
          <i className={clase.class_type.icon} /> {clase.class_type.name}
        </>
      );
    }
  };

  const taken_spots = Array.isArray(clase.class_reservations)
    ? clase.class_reservations.map(({ spot }) => spot)
    : [];

  return (
    <div className="container-fluid px-0">
      <h4 className="mb-1">{renderIcon()}</h4>
      <h3>{clase.description}</h3>
      <p className="large">
        <i className="far fa-calendar me-2"></i>{" "}
        {moment(clase.class_date).format("DD MMM YYYY")}{" "}
        <i className="far fa-clock mx-2"></i>{" "}
        {moment(clase.class_date).utc().format("HH:mm")} hrs.
      </p>
      <h5 className="mt-4">Coaches</h5>
      {renderCoaches()}
      <h5>Ubicación</h5>
      <p className="large">{clase.location.name}</p>
      <p>{clase.location.address}</p>
      {renderGuest()}
      {clase.class_type !== null && clase.class_type.spot_map !== null && (
        <MapaLugares
          place={place}
          setPlace={setPlace}
          rows={clase.class_type.spot_map
            .split(",")
            .map((num) => parseInt(num))}
          icon={clase.icon}
          taken_spots={taken_spots}
        />
      )}
      <div className="row">
        <div className="col col-md-6">
          <button className="btn w-100 text-muted" onClick={clearModal}>
            Cancelar
          </button>
        </div>
        <div className="col col-md-6">
          <button
            className="btn w-100 btn-primary"
            onClick={handleSubmit}
          >
            Reservar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleClass;
