const stripe_test_key =
  "pk_test_51HmStSKPpBTzo0mTCB7a2Q2Z4J9Yf3BGd5bchAesEpIRJxCMpx4v0odIPAkFb3FL8Rag1LUrhzd5LdDVnMQJRyMl00jVajqjeq";

const stripe_live_key =
  "pk_live_51HmStSKPpBTzo0mTpATTVNNwELwTTGVUaGhmtDzjRT0B4jYGJjcEPbolxOcgdMXeJDT0Ifo5jilJIM0fa9dnKJv900JzvGv6LA";

export const STRIPE_KEY =
  process.env.NODE_ENV === "development" ? stripe_test_key : stripe_live_key;

export const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const getCardType = (number) =>
  String(number) === "3"
    ? "amex"
    : String(number) === "4"
    ? "visa"
    : "mastercard";
