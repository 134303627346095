import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import CheckoutReducer from "../reducers/CheckoutReducer";
import CheckoutService from "../services/CheckoutService";
import {
  HIDE_SPINNER,
  SET_PAQUETE,
  SHOW_SPINNER,
  SHOW_SPINNER_DESCUENTO,
  HIDE_SPINNER_DESCUENTO,
  SET_DESCUENTO,
  SET_DISCOUNT_CODE,
  SET_PAYMENT_SOURCE,
} from "../types";
import { ModalContext } from "./ModalContext";
import PaquetesService from "../services/PaquetesService";
import DescuentosService from "../services/DescuentosService";

const initialState = {
  paquete: null,
  discountCode: "",
  class_package: null,
  payment_source_id: "card",
};

export const CheckoutContext = createContext(initialState);

export const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CheckoutReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const getSingleClassPackage = (class_package_id) => {
    PaquetesService.getSingleClassPackage(class_package_id)
      .then((res) => {
        const { class_package } = res.data;
        dispatch({ type: SET_PAQUETE, payload: class_package });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            alert(
              "Lo sentimos, no encontramos el paquete que quieres comprar."
            );
          }
        }
        alert(error);
      });
  };

  const createOrder = (class_package_id, payment_source_id, discountCode) => {
    dispatch({ type: SHOW_SPINNER });
    CheckoutService.postCheckout(
      class_package_id,
      payment_source_id,
      discountCode
    )
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        handleCheckoutSuccess(res);
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        handleCheckoutError(error);
      });
  };

  const handleCheckoutSuccess = (res) => {
    const { purchase_id } = res.data;
    success("¡Pago exitoso!");
    navigate(`/thankyou/${purchase_id}`);
  };

  const handleCheckoutError = (error) => {
    let message;
    dispatch({ type: HIDE_SPINNER });
    if (error.response) {
      const { status } = error.response;
      if (status === 400) {
        message = "No has seleccionado un médoto de pago.";
      }
      if (status === 412) {
        message =
          "Lo sentimos, se ha alcanzado el límite de personas para este paquete.";
      } else if (status === 409) {
        message =
          "Lo sentimos, ya has alcanzado el límite de compras de este paquete.";
      } else {
        message =
          "Lo sentimos. Tu tarjeta ha sido rechazada. Por favor, intenta con otro método de pago.";
      }
      return alert(message);
    }
  };

  const setPaymentSource = (payment_source_id) => {
    dispatch({ type: SET_PAYMENT_SOURCE, payload: payment_source_id });
  };

  const setPayPal = (class_package_id, discountCode) => {
    const paypalButton = document.getElementById("paypal-button");
    if(paypalButton && paypalButton === null) {
      if (paypalButton.innerHTML !== "") {
        paypalButton.innerHTML = "";
      }
      window.paypal.Button.render(
        {
          env: "production",
          payment: (data, actions) => {
            return CheckoutService.postPayPal(class_package_id, discountCode)
              .then((res) => {
                return res.data.orderID;
              })
              .catch((error) => {
                dispatch({ type: HIDE_SPINNER });
                handleCheckoutError(error);
              });
          },
          onApprove: (data, actions) => {
            return CheckoutService.capturePayPal(data).then(function (res) {
              dispatch({ type: HIDE_SPINNER });
              handleCheckoutSuccess(res);
            });
          },
        },
        "#paypal-button"
      );
    }
  };

  const showSpinner = () => {
    dispatch({ type: SHOW_SPINNER });
  };

  const hideSpinner = () => {
    dispatch({ type: HIDE_SPINNER });
  };

  const setDiscountCode = (code) => {
    dispatch({ type: SET_DISCOUNT_CODE, payload: code });
  };

  const setDescuento = (descuento) => {
    dispatch({ type: SET_DESCUENTO, payload: descuento });
  };

  const validarDescuento = (code, class_package_id) => {
    dispatch({ type: SHOW_SPINNER_DESCUENTO });
    DescuentosService.validarDescuento(code, class_package_id)
      .then((res) => {
        const { discount, error } = res.data;
        if (error) {
          alert(error.message);
        }
        dispatch({ type: SET_DESCUENTO, payload: discount });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            alert("Lo sentimos, ya has agotado este descuento.");
          }
        }
        alert("Lo sentimos, ese descuento no es válido.");
        dispatch({ type: HIDE_SPINNER_DESCUENTO });
      });
  };

  return (
    <CheckoutContext.Provider
      value={{
        ...state,
        setPayPal,
        createOrder,
        showSpinner,
        hideSpinner,
        setDescuento,
        setDiscountCode,
        setPaymentSource,
        validarDescuento,
        getSingleClassPackage,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
