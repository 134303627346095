import React from "react";

const CheckoutBillingDisclaimer = () => {
  return (
    <div className="card p-4 border br-10 no-scale mb-4">
      <h4>Recuerda</h4>
      <p>
        * Tu suscripción se renovará automáticamente cada mes hasta que la
        canceles. Puedes cancelar en cualquier momento, al cancelar, pierdes tu
        licencia para impartir clases del método Latina Tribe. Los cobros por
        las suscripciones no son reembolsables. Al realizar tu compra nos
        autorizas a cobrar a través de tu tarjeta de crédito o débito, aceptas
        nuestros Términos de servicio, Políticas de cookies y de privacidad.
      </p>
      <p></p>
    </div>
  );
};

export default CheckoutBillingDisclaimer