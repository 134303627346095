import React, { useContext } from "react";
import { ClassReservationsContext } from "../../context/ClassReservationsContext";
import { getStatusReservacionString } from "../../utils/reservations";
import { AppConfigContext } from "../../context/AppConfigContext";
import ClassReservationRating from "./ClassReservationRating";
import { ModalContext } from "../../context/ModalContext";
import CancelClass from "../schedule/CancelClass";
import { ADMIN_URL, getValue } from "../../utils";
import QRCode from "react-qr-code";
import moment from "moment";

const ReservacionRow = ({ reservacion }) => {
  const { reservations } = useContext(ClassReservationsContext);
  const { modalComponent } = useContext(ModalContext);
  const appconfig = useContext(AppConfigContext);

  const reservation_rating_enabled = getValue(
    appconfig,
    "reservation_rating_enabled",
    "boolean"
  );
  const allow_class_guests = getValue(
    appconfig,
    "allow_class_guests",
    "boolean"
  );
  const qrcode_enabled = getValue(appconfig, "qrcode_enabled", "boolean");

  const { cancelReservacion } = useContext(ClassReservationsContext);

  const displayCode = () => {
    const { class_reservation_id } = reservacion;
    let urlString = `${ADMIN_URL}/myadmin/reservation/${class_reservation_id}`;
    modalComponent(
      "Código de Acceso",
      <QRCode style={{ width: "100%" }} value={urlString} />
    );
  };

  const confirmCancel = (reservation) => {
    modalComponent(
      "Cancelar Clase",
      <CancelClass
        reservations={reservations}
        single_class={reservation.single_class}
        cancelReservacion={cancelReservacion}
      />
    );
  };

  const renderInstructors = (singleClass) => {
    if (singleClass.class_instructors.length === 1) {
      const instructor = singleClass.class_instructors[0].instructor;
      return instructor !== null
        ? instructor.nick_name !== null
          ? instructor.nick_name
          : instructor.name
        : "";
    }
    return singleClass.class_instructors.map(({ instructor }) => (
      <span className="d-block">
        {instructor !== null
          ? instructor.nick_name !== null
            ? instructor.nick_name
            : instructor.name
          : ""}
      </span>
    ));
  };

  const renderIcon = () => {
    const { single_class } = reservacion;
    if (single_class && single_class !== null) {
      const { class_type } = single_class;
      if (class_type && class_type !== null) {
        return <i className={class_type.icon}></i>;
      }
    }
  };

  const renderSpot = (reservation) => {
    if (reservation.spot !== null) {
      return (
        <span>
          <span className="show-mobile me-2">Spot</span>
          {reservation.spot}
        </span>
      );
    }
  };

  const renderActions = () => {
    if (reservacion.deletedAt !== null) {
      return <span className="badge bg-danger">Cancelada</span>;
    }
    const diff =
      moment.utc(reservacion.single_class.class_date).diff(moment(), "hours") +
      5;
    if (diff > 3) {
      return (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => confirmCancel(reservacion)}
        >
          <i className="fa fa-times"></i>
        </button>
      );
    }
  };

  const handleRating = () => {
    modalComponent(
      "Calificar Clase",
      <ClassReservationRating
        class_reservation_id={reservacion.class_reservation_id}
      />
    );
  };

  const renderRating = () => {
    if (
      getStatusReservacionString(reservacion) === "Exitosa" &&
      parseInt(reservation_rating_enabled)
    ) {
      if (reservacion.class_stars === null) {
        return (
          <button
            onClick={handleRating}
            className="btn btn-sm btn-outline-light"
          >
            <i className="fa fa-star me-1"></i> Calificar
          </button>
        );
      }
      return (
        <span>
          {reservacion.class_stars} <i className="fa fa-star text-warning"></i>
        </span>
      );
    }
  };

  return (
    <div
      key={reservacion.class_reservation_id}
      className="card reservation-card no-scale shadow-sm py-1 px-3"
    >
      <div className="row small align-items-center">
        <div className="col-12 col-md mb-2">
          <p className="mb-0 bold">
            {renderIcon()} {reservacion.single_class?.class_type?.name}
          </p>
        </div>
        {allow_class_guests && (
          <div className="col-12 col-md mb-2">{reservacion.guest}</div>
        )}
        <div className="col-12 col-md mb-2">
          {renderInstructors(reservacion.single_class)}
        </div>
        <div className="col-12 col-md mb-2">
          {moment(reservacion.single_class?.class_date)
            .utc()
            .format("ddd DD MMM, HH:mm")}
        </div>
        <div className="col-12 col-md mb-2">{renderSpot(reservacion)}</div>
        <div className="col-12 col-md mb-2">
          {qrcode_enabled && (
            <button
              className="btn btn-outline-light me-2"
              onClick={displayCode}
            >
              <i className="fa fa-qrcode"></i>
            </button>
          )}
          {renderActions()}
          {renderRating()}
        </div>
      </div>
    </div>
  );
};

export default ReservacionRow;
