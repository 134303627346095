/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import utils from "../schedule/utils";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import ScheduleMobile from "../schedule/ScheduleMobile";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import { ClassReservationsContext } from "../../context/ClassReservationsContext";
import { ClassInstructorContext } from "../../context/ClassInstructorContext";
import ScheduleClassTypePicker from "./ScheduleClassTypePicker";
import ScheduleTabs from "./ScheduleTabs";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";

const Schedule = () => {
  const view = "week";
  const [type, setType] = useState("");
  const [location, setLocation] = useState("");
  const [currentClasses, setCurrentClasses] = useState(null);
  const { getAvailableClasses } = useContext(
    AvailableClassesContext
  );

  const {
    days,
    update,
    getSchedule,
    start_date,
    end_date,
    setStartDate,
    setEndDate,
  } = useContext(ClassInstructorContext);

  const { reservations, enableReservations } = useContext(
    ClassReservationsContext
  );

  useEffect(() => {
    enableReservations();
  }, [reservations]);

  useEffect(() => {
    const date = utils.getStartDate(view);
    getAvailableClasses();
    setStartDate(date);
  }, []);

  useEffect(() => {
    setEndDate(utils.getEndDate(view, start_date));
  }, [start_date]);

  useEffect(() => {
    if (Array.isArray(days)) {
      if (view === "week") {
        setCurrentClasses(days.slice(0, 7));
      } else {
        setCurrentClasses(days);
      }
    }
  }, [days]);

  useEffect(() => {
    fetchSchedule();
  }, [start_date, end_date, update]);

  const fetchSchedule = () => {
    if (utils.validDates(start_date, end_date)) {
      getSchedule(start_date, end_date);
    }
  };

  const handleNextWeek = () => {
    setStartDate(utils.getStartNext("week", start_date));
  };

  const handlePrevWeek = () => {
    setStartDate(utils.getStartPrev("week", start_date));
  };

  const handleNextMonth = () => {
    setStartDate(utils.getStartNext("month", start_date));
  };

  const handlePrevMonth = () => {
    setStartDate(utils.getStartPrev("month", start_date));
  };

  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-12 col-md-3">
          <ScheduleMonthSelect
            start_date={start_date}
            handleNext={handleNextMonth}
            handlePrev={handlePrevMonth}
          />
        </div>
        <div className="col-12 col-md-6">
          <ScheduleWeekSelect
            start_date={start_date}
            handleNextWeek={handleNextWeek}
            handlePrevWeek={handlePrevWeek}
          />
        </div>
        <div className="col-12 col-md-3">
          <ScheduleClassTypePicker modifier={setType} selected={type} />
        </div>
      </div>
      <ScheduleTabs selected={location} setSelected={setLocation} />
      <ScheduleMobile
        type={type}
        location={location}
        weekClasses={currentClasses}
      />
    </div>
  );
};

export default Schedule;
