import React from "react";
import moment from "moment";

const ScheduleMonthSelect = ({ start_date }) => {
  return (
    <h2 className="mb-0">
      {moment(start_date).format("MMM")}{" "}
      <span className="small">{moment(start_date).format("YYYY")}</span>
    </h2>
  );
};

export default ScheduleMonthSelect;
