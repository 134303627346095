import React, { useState } from "react";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";

const AuthForm = ({ handleCallback }) => {
  const [isLogin, setIsLogin] = useState(true);

  const renderForm = () => {
    if (isLogin) {
      return (
        <LoginForm
          setSignUp={() => setIsLogin(false)}
          handleCallback={handleCallback}
        />
      );
    }
    return (
      <SignUpForm
        setLogin={() => setIsLogin(true)}
        handleCallback={handleCallback}
      />
    );
  };

  return <div>{renderForm()}</div>;
};

export default AuthForm;
