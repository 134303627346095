import api from "./api";

const route = "/purchases";

const PurchasesService = {
  getPurchases: () => api.get(route),
  getPurchase: (purchase_id) => api.get(`${route}/${purchase_id}`),
};

export default PurchasesService;
