import React from "react";

const SubmitButton = ({ label, disabled, spinner, onClick, className }) => {
  const props = {
    label,
    disabled: disabled || spinner,
  };

  if (typeof onClick === "function") {
    props.onClick = onClick;
  } else {
    props.type = "submit";
  }

  if (className && className !== null) {
    props.className = className;
  } else {
    props.className = "btn btn-primary w-100";
  }

  return (
    <button {...props}>
      {spinner ? <div className="spinner-border" /> : label}
    </button>
  );
};

export default SubmitButton;
