import React, { useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCreditCard from "./StripeCreditCard";
import { AppConfigContext } from "../../context/AppConfigContext";

const StripeCheckout = ({ element_id, discountCode }) => {
  const { stripe_public } = useContext(AppConfigContext);

  return (
    <div className="container-fluid position-relative h-100 px-0">
      <Elements stripe={loadStripe(stripe_public)}>
        <StripeCreditCard element_id={element_id} discountCode={discountCode} />
      </Elements>
    </div>
  );
};

export default StripeCheckout;
