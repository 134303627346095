import { SET_PAYMENTSOURCE, PAYMENTSOURCES_RECEIVED } from "../types/payment_sources";

const PaymentSourcesReducer = (state, { type, payload }) => {
  switch (type) {
    case PAYMENTSOURCES_RECEIVED:
      return { ...state, payment_sources: payload };
    case SET_PAYMENTSOURCE:
      return { ...state, payment_source: payload }
    default:
      return { ...state };
  }
};

export default PaymentSourcesReducer;