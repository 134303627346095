
import api from './api';

const route = '/available_classes';

const AvailableClassesService = {
  getAvailableClasses: () => api.get(route),
  getSingleAvailableClass: (available_class_id) => api.get(`${route}/${available_class_id}`),
  postAvailableClass: (available_class) => api.post(route, { ...available_class}),
  putAvailableClass: (available_class) => api.put(route, { ...available_class}),
  deleteAvailableClass: (AvailableClass) => api.delete(`${route}/${AvailableClass}`),
};

export default AvailableClassesService;