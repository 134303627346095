export const handleCheckoutError = (error, callback) => {
  if (typeof error === "string") {
    return callback(error);
  }
  let message = "Hubo un error al procesar tu compra. Inténtalo más tarde.";
  if (error.response) {
    const { status, data } = error.response;
    if (status === 402) {
      if (data.code === "card_declined") {
        if (data.decline_code === "insufficient_funds") {
          message =
            "Lo sentimos. Tu tarjeta ha sido rechazada por fondos insuficientes.";
        } else if (
          data.decline_code === "lost_card" ||
          data.decline_code === "stolen_card"
        ) {
          message =
            "Lo sentimos. Esta tarjeta ha sido reportada como robada o perdida y no puede utilizarse.";
        } else {
          message =
            "Lo sentimos. Tu tarjeta ha sido rechazada por tu banco. Comunícate con tu banco para saber más.";
        }
      } else if (data.code === "expired_card") {
        message = "Lo sentimos. Tu tarjeta ha expirado";
      } else if (data.code === "incorrect_cvc") {
        message = "Lo sentimos. El CVV de tu tarjeta es incorrecto";
      } else if (data.code === "incorrect_number") {
        message = "Lo sentimos. El número de la tarjeta es incorrecto.";
      }
    } else if (status === 400) {
      message = "No has seleccionado un método de pago.";
    } else if (status === 412) {
      if (data) {
        message = data.message;
      } else {
        message =
          "Lo sentimos, se ha alcanzado el límite de personas para este paquete.";
      }
    } else if (status === 409) {
      message =
        "Lo sentimos, ya has alcanzado el límite de compras de este paquete.";
    } else if (data && data !== null) {
      if (data.message && data.message !== null) {
        message = data.message;
      }
    }
  }
  if (!message) {
    message = "Hubo un error al procesar tu compra. Inténtalo más tarde.";
  }
  return callback(message);
};
