import React, { useContext, useEffect } from "react";
import { ClassTypeContext } from "../../context/ClassTypesContext";

const ScheduleClassTypePicker = ({ modifier, selected }) => {
  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  useEffect(() => {
    getClassTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderClassTypes = () => {
    if (Array.isArray(class_types)) {
      return [
        <option key="all" value="">
          Todas las Clases
        </option>,
        ...class_types.map((class_type) => (
          <option
            key={class_type.class_type_id}
            value={class_type.class_type_id}
          >
            {class_type.name}
          </option>
        )),
      ];
    }
  };

  const renderPicker = () => {
    if (Array.isArray(class_types)) {
      if (class_types.length > 1) {
        return (
          <div className="container-fluid px-0">
            <label>Tipo de Clase</label>
            <select
              value={selected}
              className="form-control"
              onChange={(e) => modifier(e.target.value)}
            >
              {renderClassTypes()}
            </select>
          </div>
        );
      }
    }
  };

  return <div className="row ms-0 mb-0">{renderPicker()}</div>;
};

export default ScheduleClassTypePicker;
