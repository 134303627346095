import React, { useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import { AuthContext } from "../context/AuthContext";
import { formatMonto } from "../utils";
import { Link } from "@reach/router";
import moment from "moment";
import StatusBadge from "../components/global/StatusBadge";

const Gracias = ({ purchase_id }) => {
  const { purchase, getPurchase } = useContext(PurchasesContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null && purchase === null) {
      getPurchase(purchase_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, purchase_id]);

  const renderTotal = () => {
    if (
      purchase.class_package !== null &&
      purchase.free_trial_length !== null
    ) {
      return (
        <div>
          <p>Total Pagado: $0 MXN</p>
          <p>
            Siguiente Cargo: ${formatMonto(purchase.class_package.price)} MXN el{" "}
            {moment()
              .add(purchase.free_trial_length, "days")
              .format("DD MMM YYYY")}
          </p>
        </div>
      );
    }
    return <p>Total: ${formatMonto(purchase.total_payment)} MXN</p>;
  };

  const renderOrden = () => {
    if (purchase && purchase !== null) {
      return (
        <div>
          <div className="mb-4">
            <h3>{purchase.title}</h3>
            <p>
              Fecha: {moment(purchase.created_at).format("DD MMM YYYY HH:mm")}
            </p>
            {renderTotal()}
            <StatusBadge status={purchase.status} />
          </div>
          <h4 className="border-bottom mt-4 pb-2">Detalles</h4>
          <p>Clases Compradas: {purchase.class_amount}</p>
          <p>Método de Pago: {purchase.payment_method?.name}</p>
          <p>Cuenta: {purchase.customer?.email}</p>
          <p>
            Tus Beneficios{" "}
            {purchase.subscription_period !== null
              ? "renuevan cada"
              : "expiran en"}{" "}
            {purchase.expiration_days} días.
          </p>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid vh-100 pt-5">
      <div className="container py-5">
        <h1 className="text-center m-4">Orden #00{purchase_id}</h1>
        <div className="card no-scale mw-500 m-auto d-block p-4">
          {renderOrden()}
          <Link to="/mylegion" className="btn btn-primary bold d-block">
            Ir a Mis Clases
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Gracias;
