import React, { useState, useEffect, useContext } from "react";
import { MilestonesContext } from "../../context/MilestonesContext";
import MilestoneImage from "../milestones/MilestoneImage";
import { AuthContext } from "../../context/AuthContext";

const AccountMilestone = () => {
  const [loading, setLoading] = useState(true);

  const { user } = useContext(AuthContext);
  const { milestone, setMilestone } = useContext(MilestonesContext);

  useEffect(() => {
    if (user && user !== null) {
      if (user.milestone && user.milestone !== null) {
        setMilestone(user.milestone);
      }
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const renderMilestone = () => {
    if (milestone && milestone !== null) {
      return (
        <div className="row">
          <div className="col-2">
            <MilestoneImage
              milestone={milestone}
              className="mw-100 w-100 d-block m-auto"
            />
          </div>
          <div className="col-10">
            <h3>{milestone.name}</h3>
            <p>{milestone.description}</p>
          </div>
        </div>
      );
    }
    if (loading) return <div className="spinner-border"></div>;
    return <p className="mx-2">Aún no has alcanzado un nivel.</p>;
  };

  return (
    <div className="card py-3 px-2 bg-gray border my-3">
      <h4 className="mx-2">Nivel</h4>
      {renderMilestone()}
    </div>
  );
};

export default AccountMilestone;
