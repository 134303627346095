import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import AuthForm from "../auth/AuthForm";
import SingleClass from "./SingleClass";
import CancelClass from "./CancelClass";
import { Link, navigate } from "@reach/router";
import { canCancel } from "../../utils/reservations";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ClassReservationsContext } from "../../context/ClassReservationsContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";
import { getValue } from "../../utils";

const ScheduleClass = ({ single_class, isHome }) => {
  const [needsLogin, setNeedsLogin] = useState(false);
  const { user, getUsuario } = useContext(AuthContext);

  const appconfig = useContext(AppConfigContext);
  const { cancel_timeframe_value } = appconfig;
  const show_all_spots = getValue(appconfig, "show_all_spots", "boolean");
  const allow_class_guests = getValue(
    appconfig,
    "allow_class_guests",
    "boolean"
  );
  const allow_cancel_after_treshold = getValue(
    appconfig,
    "allow_cancel_after_treshold",
    "boolean"
  );

  const { clearModal, modalComponent } = useContext(ModalContext);
  const { available_classes } = useContext(AvailableClassesContext);
  const { reservations, postReservacion, cancelReservacion } = useContext(
    ClassReservationsContext
  );
  const customerSpots =
    reservations?.filter(
      ({ single_class_id, deletedAt }) =>
        single_class_id === single_class.single_class_id && deletedAt === null
    ).length || 0;
  const remainingSpots =
    single_class?.capacity - single_class?.class_reservations?.length;

  useEffect(() => {
    if (user !== null && needsLogin) {
      clearModal();
      setTimeout(() => {
        setNeedsLogin(false);
        reservarClase();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const showClassModal = () => {
    if (isHome) {
      return navigate("/entrar");
    }
    if (isReserved()) {
      modalComponent(
        "Cancelar clase",
        <CancelClass
          single_class={single_class}
          reservations={reservations}
          cancelReservacion={(class_reservation_id) =>
            cancelReservacion(class_reservation_id, getUsuario)
          }
        />
      );
    }
  };

  const isFull = () => {
    if (single_class !== null) {
      if (
        single_class.capacity !== null &&
        Array.isArray(single_class.class_reservations)
      ) {
        return (
          single_class.capacity - single_class.class_reservations.length <= 0
        );
      }
    }
  };

  const isReserved = () => {
    if (reservations && reservations !== null) {
      let class_ids = reservations
        .filter((reservation) => reservation.deletedAt === null)
        .map(({ single_class_id }) => single_class_id);
      return class_ids.includes(single_class.single_class_id);
    }
    return false;
  };

  const reservarClase = () => {
    if (isHome) {
      return navigate(`/checkout/152`);
    }
    if (single_class.class_package_id !== null) {
      return navigate(`/checkout/${single_class.class_package_id}`);
    }
    if (!isFull()) {
      if (user === null) {
        setNeedsLogin(true);
        return modalComponent("Debes Acceder para Reservar", <AuthForm />, {
          no_padding: true,
        });
      }
      if (available_classes?.length > 0) {
        modalComponent(
          "Reservar Clase",
          <SingleClass
            clase={single_class}
            guest={customerSpots > 0}
            postReservacion={(clase) => postReservacion(clase, getUsuario)}
          />,
          () => {},
          () => postReservacion(single_class)
        );
      } else {
        modalComponent(
          "Créditos Agotados",
          <div className="container-fluid px-0">
            <p>
              Lo sentimos. Has agotado todos tus créditos para reservar Clases
              Presenciales. Puedes comprar más en Paquetes.
            </p>
            <p className="bold">
              Después de comprar, debes regresar a reservar tu clase.
            </p>
            <Link
              to="/mylegion/paquetes"
              onClick={clearModal}
              className="btn w-100 btn-primary"
            >
              Ir a Paquetes
            </Link>
          </div>
        );
      }
    }
  };

  const renderBadges = () => {
    const components = [];
    if (isFull()) {
      components.push(
        <div key="spots">
          <span className="badge badge-pill bg-primary-light">Sold Out</span>
        </div>
      );
    } else if (show_all_spots) {
      let spots =
        single_class.capacity - single_class.class_reservations.length;
      components.push(
        <div key="spots">
          <span className="badge d-inline-block my-1 badge-pill bg-primary-light text-dark">
            {spots} lugar{spots === 1 ? "" : "es"}
          </span>
        </div>
      );
    }
    if (Array.isArray(single_class.tags)) {
      single_class.tags.forEach((tag) =>
        components.push(
          <div key="spots">
            <span className="badge d-inline-block bg-accent badge-pill my-1">
              {tag.name}
            </span>
          </div>
        )
      );
    }
    return <div className="d-block mb-1">{components}</div>;
  };

  const renderButtons = () => {
    if (isReserved()) {
      return (
        <div className="container-fluid px-0 mt-2 text-center">
          {allow_class_guests && (
            <span className="mb-2 d-block small text-primary">
              {customerSpots} reserva{customerSpots > 1 ? "s" : ""}
            </span>
          )}
          {allow_class_guests && remainingSpots > 0 && (
            <button
              className="btn btn-block btn-sm mb-2 btn-outline-primary"
              onClick={reservarClase}
            >
              + 1 <i className="fa fa-user" />
            </button>
          )}
          {(allow_cancel_after_treshold ||
            canCancel(single_class, cancel_timeframe_value)) && (
            <button
              className="btn btn-sm btn-outline-danger mt-2"
              onClick={
                customerSpots > 1
                  ? () => navigate("/mylegion/reservaciones")
                  : showClassModal
              }
              style={{ fontSize: 10 }}
            >
              Cancelar
            </button>
          )}
        </div>
      );
    }
    if (single_class.class_package_id !== null) {
      return (
        <button
          className="btn btn-dark btn-sm mt-2"
          onClick={() => navigate(`/checkout/${single_class.class_package_id}`)}
        >
          Comprar
        </button>
      );
    }
    const diff =
      moment(single_class.class_date)
        .utc()
        .local()
        .diff(moment().utc().local(), "hours") + 6;
    if (diff > 0 && !isFull()) {
      return (
        <button
          className="btn bg-primary text-white btn-sm mt-2"
          onClick={reservarClase}
        >
          Reservar
        </button>
      );
    }
  };

  const renderInstructors = () => {
    return single_class.class_instructors.map((class_instructor) => (
      <span
        className="badge badge-pill me-1 bg-dark px-2"
        key={class_instructor.instructor_id}
      >
        {class_instructor.instructor.name}
      </span>
    ));
  };

  const renderClassTypeIcon = () => {
    if (single_class.class_type.icon !== null) {
      return <i className={`${single_class.class_type.icon} me-1`} />;
    }
  };

  const renderClassType = () => {
    if (single_class.class_type !== null) {
      return single_class.class_type.name;
    }
  };

  return (
    <div
      className={`schedule-class ${
        single_class.class_package_id !== null ? "bg-special" : ""
      } my-2 p-3 ${
        isReserved()
          ? "bg-primary-light text-dark"
          : isFull()
          ? "bg-primary text-white"
          : "bg-light text-black"
      }`}
    >
      <p className="mb-0 bold">
        {renderClassTypeIcon()} {renderClassType()}
      </p>
      <p className="mb-1 small">{single_class.description}</p>
      <p className="font-weight-bold mb-1">
        <i className="far fa-clock"></i>{" "}
        {moment(single_class.class_date).utc().format("HH:mm")}
      </p>
      <div>{renderInstructors()}</div>
      {renderBadges()}
      {renderButtons()}
    </div>
  );
};

export default ScheduleClass;
