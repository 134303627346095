import React from "react";

const CheckoutHeader = () => {
  return (
    <div className="row border-bottom align-items-center pb-3 pt-3">
      <div className="container-fluid">
        <div className="container">
          <h1 className="mb-0">Checkout</h1>
        </div>
      </div>
    </div>
  );
};

export default CheckoutHeader;
