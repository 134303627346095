import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { getValue } from "../utils";
import { Link } from "@reach/router";
import ReservacionRow from "../components/reservaciones/ReservacionRow";
import { ClassReservationsContext } from "../context/ClassReservationsContext";
import { AppConfigContext } from "../context/AppConfigContext";
import { AvailableClassesContext } from "../context/AvailableClassesContext";

const MisReservaciones = () => {
  const { available_classes, getAvailableClasses } = useContext(
    AvailableClassesContext
  );
  const { reservations, getMyReservations } = useContext(
    ClassReservationsContext
  );
  const appconfig = useContext(AppConfigContext);

  const allow_class_guests = getValue(
    appconfig,
    "allow_class_guests",
    "boolean"
  );

  const [filtered, setFiltered] = useState(true);

  useEffect(() => {
    getMyReservations();
    getAvailableClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderReservaciones = () => {
    if (Array.isArray(reservations)) {
      if (reservations.length === 0)
        return (
          <div className="container-fluid px-0">
            <p>Aún no has reservado clases.</p>
            <Link to="/mylegion/presencial" className="btn btn-primary">
              Ir a Reservar
            </Link>
          </div>
        );
      let reservationsRender = reservations;
      if (filtered) {
        reservationsRender = reservationsRender.filter((reservation) =>
          moment(reservation.single_class.class_date)
            .utc()
            .isAfter(moment().utc().subtract(1, "days"))
        );
      }
      return (
        <>
          <div className="row border mx-0 py-2 bold hide-mobile small">
            <div className="col">Clase</div>
            {allow_class_guests && <div className="col">Invitado</div>}
            <div className="col">Coach</div>
            <div className="col">Fecha/Hora</div>
            <div className="col">Lugar</div>
            <div className="col">Acciones</div>
          </div>
          {reservationsRender.map((reservation) => (
            <ReservacionRow
              key={reservation.class_reservation_id}
              reservacion={reservation}
            />
          ))}
        </>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderCancelTimeFrame = () => {
    const value = getValue(appconfig, "cancel_timeframe_value", "string");
    const unit = getValue(appconfig, "cancel_timeframe_unit", "string");

    const unitTranslation = {
      hours: "horas",
      minutes: "minutos",
      seconds: "segundos",
    };

    return `Solo puedes cancelar clases con ${value} ${unitTranslation[unit]} de anticipacion`;
  };

  return (
    <div className="container-fluid py-3">
      <div className="row mb-3">
        <div className="col-12 col-md-6 mb-2">
          <h1 className="h2">Mis Reservaciones</h1>
          <p className="mb-0">{renderCancelTimeFrame()}</p>
        </div>
        <div className="col-12 col-md-6 mb-2 text-end mobile-left">
          <h4>Restantes: {available_classes?.length}</h4>
          <button
            className="btn btn-outline-primary me-3 mb-2"
            onClick={() => setFiltered(!filtered)}
          >
            {filtered ? "Mostrar" : "Ocultar"} Anteriores
          </button>
          <Link to="/mylegion/paquetes" className="btn btn-primary mb-2">
            Comprar Clases
          </Link>
        </div>
      </div>
      {renderReservaciones()}
    </div>
  );
};

export default MisReservaciones;
