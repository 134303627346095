import React from "react";

const MilestoneImage = ({ milestone, className }) => {
  const renderImage = () => {
    const { file } = milestone;
    if (file && file !== null) {
      return (
        <img
          className={className}
          alt={milestone.name}
          src={file.src}
        />
      );
    }
    return <i className="fa fa-trophy mx-2" />;
  };
  return <div>{renderImage()}</div>;
};

export default MilestoneImage;
