import React from "react";

const StatusBadge = ({ status, order_id }) => {
  return (
    <div>
      {status === "revoked" ? (
        <span className="badge badge-pill bg-danger">Revocada</span>
      ) : status === "cancelled" ? (
        <span className="badge badge-pill bg-danger">Cancelada</span>
      ) : status === "active" ? (
        <span className="badge badge-pill bg-success">Activa</span>
      ) : status === "success" || status === "completed" ? (
        <span className="badge badge-pill bg-success">Completada</span>
      ) : (order_id === null && status === "pending") ||
        String(status).toLowerCase() === "failed" ? (
        <span className="badge badge-pull bg-danger text-white">Rechazada</span>
      ) : status === "pending" ? (
        <span className="badge badge-pull bg-warning text-dark">Pendiente</span>
      ) : (
        <span className="badge badge-pull bg-secondary text-capitalize">
          {status}
        </span>
      )}
    </div>
  );
};
export default StatusBadge;
