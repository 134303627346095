import React from "react";
import { Link } from "@reach/router";
import Footer from "../components/global/Footer";
import Schedule from "../components/schedule/Schedule";
// import InstructorsCollage from "../components/instructors/InstructorsCollage";
import Shop from "./Shop";

const Landing = () => {
  return (
    <div id="landing">
      <div id="video" className="row align-items-center">
        <div className="container-fluid text-center">
          <img
            src="/images/logo-blanco-azul.png"
            className="logo m-auto d-block"
            alt="logo"
          />
          <Link
            id="main-cta"
            to="/entrar"
            className="btn btn-primary bold btn-lg m-auto landing-button"
          >
            JOIN US
          </Link>
        </div>
      </div>
      <div className="container px-2 py-4">
        <div id="about" className="row align-items-center">
          <div className="container-fluid">
            <h2>About LEGION</h2>
            <p className="h4">
              The new and revolutionary experience of indoor cycling, we will
              inspire and transform you to achieve your goals, Buildded in
              powerful bases, technical and rhythmically synced, embracing the
              methodology of encouraging yourself to move forward and become
              unstoppable.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid px-2 p-4" id="calendario">
        <div className="container">
          <h2 className="mb-4">Calendario de Clases</h2>
          <Schedule isHome={true} hidePrevious />
        </div>
      </div>
      <Shop />
      <Footer />
    </div>
  );
};

export default Landing;
